<template>
  <div class="wrapper">
    <!-- <snowfall></snowfall> -->

    <!-- <Slider></Slider> -->
    <!-- Nav tabs -->
    <div class="scrollable-wrapper">
      <ul style="margin-bottom: 5px;" class="nav nav-tabs scrollable-list" role="tablist">
        <!-- Dynamic tabs for live sports -->
        <li style="border: solid var(--red) 1px;color: black;margin-right: 2px;" v-for="sport in liveSports" :key="sport.sport_id" class="nav-item mb-a-12">
          <a
              :class="['nav-link', {active: sport_id === sport.sport_id, 'mb-a-12': sport_id !== sport.sport_id}]"
              @click="setSport1(sport.sport_id)"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              style="display: inline-block;"
          >
            {{ sport.sport_name }} ({{ sport.games }})
          </a>
        </li>
      </ul>
    </div>


    <div class="body">
      <!-- <Highlights></Highlights> -->

      <Alllive
          class="pl-2 pr-2"
          v-bind:sport="sport_id"
          upcoming="0"
          leo="true"
          v-show="current_sub_page !== 'countries'"
      ></Alllive>
      
      <bottom-navigation active-bets="0" page="home"></bottom-navigation>
    </div>
  </div>
</template>

<script>
// import Highlights from './Highlights'
// import Slider from './Slider'
import Alllive from "./Alllive";
// import BottomFooter from "./BottomFooter";
import BottomNavigation from "./BottomNavigation";
import fix from "@/services/fix";
// import Snowfall from './Snowfall.vue'
// const Trending = () => import('./Trending.vue')

export default {
  name: "Live",
  metaInfo() {
    return {
      title: "Live betting on games | Bet on live games at ponyoka ",
      meta: [
        {
          name: "description",
          content:
              "Register or login for live betting games in Kenya from ponyoka. Enjoy the best odds for sports during the game.",
        },
        {
          name: "keywords",
          content:
              "ponyoka, sports betting, online betting ,Live games, Live Betting games, ponyoka Live Betting games4 Live online betting, ponyoka Live, ponyoka Live games, betting experience",
        },
        { canonical: "https://ponyoka.com/live" },
      ],
    };
  },
  components: {
    // Slider,
    // Highlights,
    Alllive,
    // Snowfall,
    // Trending,
    // BottomFooter,
    BottomNavigation,
  },
  data: function () {
    return {
      sub_page: "",
      total_game_count: 0,
      liveSports: []
    };
  },
  mounted() {
    this.sports();

    var vm = this;
    this.$store.dispatch("setCurrentPage", "live");
    //this.$store.dispatch("setSportID",1);
    this.$store.dispatch("setHour", -1);
    //this.$store.dispatch("setMarketID",1);
    this.$store.dispatch("setCompetitionID", 0);

    this.EventBus.$on("total-games", function (count){

      vm.total_game_count = count;
    });

    // reset game filter
    //this.$store.dispatch("resetAllGames");
    this.reloadProfile();
  },
  computed: {
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
  },
  methods: {
    setSubPage: function (page) {
      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;
    },
    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? "active" : "";
    },
    setSport1: function (sportID) {
      sportID = parseInt(sportID);
      this.$store.dispatch("setSportID", sportID);
    },
    /*sports: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/live/counter";

      fix
          .get(path)
          .then((res) => {
            vm.liveSports =  res.data.sports;
          })
          .catch((error) => {
            console.log(error);
          });
    },*/

    sports: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/live/counter";

      fix
          .get(path)
          .then((res) => {
            const hasSportId1 = res.data.sports && res.data.sports.some(sport => sport.sport_id === 1);
            // console.log("Live Sport-->" + hasSportId1);
            if (hasSportId1) {
              vm.liveSports = res.data.sports;
            }else{
              const firstSportId = res.data.sports[0].sport_id;
              console.log("Live Sport-->" + firstSportId);
              vm.$store.dispatch("setSportID", firstSportId);
              vm.liveSports = res.data.sports;
            }
            //vm.liveSports =  res.data.sports;
          })
          .catch((error) => {
            console.log(error);
          });
    },

  },
};
</script>

<style scoped>

.scrollable-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.scrollable-list {
  display: flex;
}

.nav-link {
  cursor: pointer;
  color: black;
  background-color: white;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-link.active{
  color: #495057;
  background-color: var(--secondary);
  border-color: #dee2e6 #dee2e6 #fff;
}

@media (max-width: 528px) {
  .scrollable-list {
    flex-wrap: nowrap;
  }

  .nav-tabs .nav-item {
    flex: 0 0 auto;
    cursor: pointer;
  }
}
</style>